@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

div[id^='select-wrapper'] input {
  height: 40px;
  border-radius: 0.375rem;
}
div[id^='select-wrapper'] span {
  padding-top: 3px;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.arrow {
  position:relative;
  display:inline-block;
}
.arrow:after {
  content: '';
  font: 1em"Consolas", monospace;
  width: 0;
  height: 0;
  position: absolute;
  right: 15px;
  top: 11px;
  margin-right: 0;
  border-width: 8px 0px 8px 8px;
  border-style: solid;
  border-color: transparent #a2a2a2;
  pointer-events: none;
  transform: rotate(90deg)
}

[data-amplify-authenticator] {
  --amplify-colors-font-primary: #FFFFFF;

  --amplify-colors-border-primary: var(--amplify-colors-neutral-60);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-60);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-primary-10: var(--amplify-colors-neutral-100);
  --amplify-colors-brand-primary-20: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-primary-40: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-primary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-neutral-10);
  --amplify-colors-font-interactive: var(--amplify-colors-neutral-60);
  --amplify-components-heading-color: var(--amplify-colors-neutral-60);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-focus-color: var(--amplify-colors-neutral-100);
  --amplify-components-tabs-item-hover-color: var(--amplify-colors-neutral-100);

  --amplify-components-authenticator-state-inactive-background-color: var(--amplify-colors-neutral-40);

  --amplify-components-button-link-active-background-color: var(--amplify-colors-neutral-60);
  --amplify-components-button-link-active-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-button-link-active-color: var(--amplify-colors-neutral-60);
  --amplify-components-button-link-color: var(--amplify-colors-neutral-60);
  --amplify-components-button-link-focus-color: var(--amplify-colors-neutral-60);
  --amplify-components-button-link-focus-background-color: var(--amplify-colors-neutral-60);
  --amplify-components-button-link-hover-color: var(--amplify-colors-neutral-100);
  --amplify-components-button-link-hover-background-color: var(--amplify-colors-neutral-20);

  --amplify-components-button-primary-background-color: #2563EB;
  --amplify-components-button-primary-active-background-color: #2563EB;
  --amplify-components-button-primary-hover-background-color: #1D4ED8;
  --amplify-components-button-primary-focus-background-color: #1D4ED8;

  --amplify-components-button-hover-border-color: var(--amplify-colors-neutral-100);
  --amplify-components-button-hover-background-color: var(--amplify-colors-neutral-20);
  --amplify-components-button-hover-color: var(--amplify-colors-neutral-100);

  --amplify-components-router-radius: var(--amplify-radii-medium);
  --amplify-components-container-radius: var(--amplify-radii-medium);
  --amplify-components-card-border-radius: var(--amplify-radii-medium);
  --amplify-components-badge-border-radius: var(--amplify-radii-medium);
  --amplify-components-card-elevated-border-radius: var(--amplify-radii-medium);
  --amplify-components-card-outlined-border-radius: var(--amplify-radii-medium);
  --amplify-components-checkbox-button-before-border-radius: var(--amplify-radii-medium);
  --amplify-components-checkbox-icon-border-radius: var(--amplify-radii-medium);
  --amplify-components-expander-border-radius: var(--amplify-radii-medium);
  --amplify-components-expander-item-border-bottom-left-radius: var(--amplify-radii-medium);
  --amplify-components-expander-item-border-bottom-right-radius: var(--amplify-radii-medium);
  --amplify-components-expander-item-border-end-end-radius: var(--amplify-radii-medium);
  --amplify-components-expander-item-border-end-start-radius: var(--amplify-radii-medium);
  --amplify-components-expander-item-border-start-end-radius: var(--amplify-radii-medium);
  --amplify-components-expander-item-border-start-start-radius: var(--amplify-radii-medium);
  --amplify-components-expander-item-border-top-left-radius: var(--amplify-radii-medium);
  --amplify-components-expander-item-border-top-right-radius: var(--amplify-radii-medium);
  --amplify-components-fieldcontrol-border-radius: var(--amplify-radii-medium);
  --amplify-components-fieldcontrol-quiet-border-radius: var(--amplify-radii-medium);
  --amplify-components-menu-border-radius: var(--amplify-radii-medium);
  --amplify-components-pagination-item-shared-border-radius: var(--amplify-radii-medium);
  --amplify-components-placeholder-border-radius: var(--amplify-radii-medium);
  --amplify-components-radio-button-border-radius: var(--amplify-radii-medium);
  --amplify-components-sliderfield-range-border-radius: var(--amplify-radii-medium);
  --amplify-components-sliderfield-thumb-border-radius: var(--amplify-radii-medium);
  --amplify-components-sliderfield-track-border-radius: var(--amplify-radii-medium);
  --amplify-components-switchfield-thumb-border-radius: var(--amplify-radii-medium);
  --amplify-components-switchfield-track-border-radius: var(--amplify-radii-medium);
  --amplify-components-button-border-radius: var(--amplify-radii-medium);
}

[data-amplify-router] {
  border-radius: var(--amplify-radii-medium);
}

button.amplify-tabs-item {
  border-top-right-radius: var(--amplify-radii-medium);
  border-top-left-radius: var(--amplify-radii-medium);
}

div.amplify-tabs {
  border-radius: var(--amplify-radii-medium);
}

div[role*='tablist'] {
  border-radius: var(--amplify-radii-medium);
}
